* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  font-family: 'Inter', sans-serif; /* Use Inter font-family as in style.css */
  background-color: #171D22; /* Ensure background color covers the entire page */
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Ensures images fit well in containers */
}

.App {
  display: flex;
  background-color: #171d22;
  height: 97vh; /* Adjusted to 97vh from style.css */
  color: #fff; /* White text color */
  overflow: hidden;
}

.left-side, .right-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

/* Make left side scrollable */
.left-side {
  height: 100%; /* Make sure the left side takes full height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.right-side {
  margin: 40px 40px 40px 0;
  border-radius: 24px;
  background: linear-gradient(172deg, #336FE4 5.66%, #5633E4 96.49%);
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire div without distortion */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .App {
    flex-direction: column;
  }

  .right-side {
    display: none;
  }

  .preview-image {
    height: auto;
  }
}

/* Footer styles */
.App-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 48%; /* Set the footer width to match the left side */
  display: flex;
  justify-content: center; /* Center the links horizontally */
  align-items: center; /* Center the links vertically */
  padding: 10px 0 20px 0; /* Added padding at the bottom */
  background-color: #171d22ff;
  font-family: 'Inter', sans-serif;
}

.footer-links {
  display: flex;
  flex-direction: row; /* Align the links in a row */
  justify-content: center;
  padding: 30px 0;
}

.footer-link {
  color: #788493ff;
  text-decoration: none;
  margin-right: 20px; /* Space between links */
  font-size: 16px;
}

.footer-link:last-child {
  margin-right: 0; /* Remove the margin after the last link */
}

ul {
  padding: 0;
}

ul li {
  display: inline;
}

/* Button styles */
.btn {
  width: 100%; 
  margin-top: 20px; 
  padding: 12px; 
  font-size: 16px; 
  font-weight: bold; 
  background-color: #336FE4; 
  color: white; 
  border: none; 
  border-radius: 4px; 
  cursor: pointer; 
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #2E64CD; 
}